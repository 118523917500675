import React from "react";

import Main from "components/layout/Main";
import Heading from "components/Heading";

import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import PointerParent from "components/PointerParent";
import RawHTMLStyled from "components/RawHTMLStyled";

import backgroundOverlay from "assets/images/header_skew/faq_header.jpg";

import ScrollToTop from "components/ScrollToTop";

export default function FaqPage() {
  const points = ["headingslim1", "headingslim2", "headingslim3"];
  return (
    <Main page={"fqa"} pagetitle="Frequently Asked Questions">
      <HeaderSkew
        title="Frequently Asked Questions"
        subtitle=""
        url="faq"
        background={backgroundOverlay}
      />
      <PointerParent points={points}>
        <Block layer="1" name="" guides={1} appearance="grid-top--home" />

        <Block layer="1" name="FAQ" guides={1} appearance="grid-home">
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h1--regular" id={points[0]}>
              Solar
            </Heading>
            <RawHTMLStyled>
              <>
                {/*    */}

                <h2>
                  WILL I STILL HAVE AN ELECTRICITY BILL FROM MY UTILITY AFTER
                  GOING SOLAR?
                </h2>
                <p>
                  Yes, most of our customers will still pay a vastly reduced
                  Electricity Bill to their utility in addition to their monthly
                  loan payment to Loanpal. Typically, homeowners consume more
                  electricity than the solar system generates, therefore, you
                  will still owe your utility for that excess consumption and
                  potentially for interconnection charges. However, if the solar
                  system generates more electricity than you use, you may be
                  eligible for credits from your utility provider.
                </p>
                {/*    */}

                <h2>HOW DOES THE FEDERAL RESIDENTIAL TAX CREDIT (ITC) WORK?</h2>
                <p>
                  The Residential Tax Credit (sometimes called “ITC” or “RTC”)
                  in 2020 is a 26% federal tax credit for solar systems on
                  residential properties. As the owner of the solar energy
                  system, you may be eligible to apply the credit as a
                  dollar-for-dollar reduction in the federal income taxes that
                  you owe in the year that you purchased your solar system. If
                  the RTC granted for your solar system is greater than your tax
                  liabilities in the year that you purchased your solar energy
                  system, you may be able to apply the remaining RTC in the
                  subsequent year. To determine your eligibility for any federal
                  solar residential tax credit, you should make an independent
                  assessment or consult with your tax advisor. Here’s an
                  example:
                </p>
                <p>
                  The typical homeowner that goes solar with Loanpal pays about
                  $30,000 for their solar installation. In this example, the 26%
                  federal tax credit could reduce a borrower’s taxes by $7,800 –
                  a nice bonus! Through December 31, 2022 homeowners qualify for
                  a federal tax credit worth up to 26% of the total cost of the
                  solar installation. To qualify for the solar federal tax
                  credit, a borrower must meet all the following requirements:
                </p>
                <ul>
                  <li>Own their home (renters are excluded)</li>
                  <li>
                    Their federal tax liability must be sufficient to qualify
                    for the tax credit
                  </li>
                  <li>
                    Own their solar system (financing the system with a loan
                    from Loanpal meets this requirement)
                  </li>
                </ul>
                {/*    */}

                <h2>
                  WHAT’S THE DIFFERENCE BETWEEN A TAX CREDIT AND A TAX REBATE?
                </h2>
                <p>
                  The ITC is a federal tax credit, not a rebate. Tax credits
                  offset the balance of tax due to the government Therefore, you
                  must have a tax appetite to be able to benefit from the tax
                  credit. Please note, people who do not pay federal taxes may
                  not be able to take full benefit of the tax credit This
                  includes:
                </p>
                <ul>
                  <li>Borrowers on fixed income</li>
                  <li>Borrowers that are retired</li>
                  <li>Borrowers that worked only part of the year</li>
                </ul>
                <p>
                  If a borrower does pay enough in federal taxes the year they
                  finance (or purchase) a solar system, the credit can be
                  applied to pay off the taxes due, Borrowers can claim the tax
                  credit over two tax cycles if they don’t have the full tax
                  liability in year 1. If the borrower already paid taxes by
                  withholding them from their paycheck, the federal government
                  will apply issue a tax refund for the delta.
                </p>
                {/*    */}

                <h2>HOW EXACTLY ARE MY LOAN PAYMENTS STRUCTURED?</h2>
                <p>
                  The loan is structured to give you the benefit of using the
                  26% Federal Residential Tax Credit to pay down your loan, so
                  that your initial monthly payments are kept low. After month
                  18, your loan will re-amortize, and your new monthly payment
                  will be adjusted so your loan is fully repaid by the end of
                  your term. Most of our loans* follow the following 3 scenarios
                  after the month 18 is passed:
                </p>
                <p>
                  <strong>A.</strong> If you pay down your loan 26% by month 18,
                  your monthly payment will remain approximately the same
                  throughout the life of your loan.
                </p>
                <p>
                  <strong>B.</strong> If you do not pay down your loan 26% by
                  month 18, your new monthly payment will be higher than your
                  initial monthly payment.
                </p>
                <p>
                  <strong>C.</strong> If you pay down your loan by more than 26%
                  by month 18, your new monthly payment will be less than your
                  initial monthly payment.
                </p>
                <p>
                  * Please note our Flexpay loans are structured so that
                  borrowers can pay as little as just interest for the first 18
                  months. For questions about these loan payments, please
                  reference your Loan Documents or call our customer support:{" "}
                  <a
                    href="tel:1.844.LOANPAL"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555", textDecoration: "underline" }}
                  >
                    1.844.LOANPAL
                  </a>
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h1--regular" id={points[1]}>
              Payments
            </Heading>

            <RawHTMLStyled>
              <>
                {/*    */}

                <h2>WHEN IS MY FIRST PAYMENT DUE?</h2>

                <p>
                  Your first payment date is indicated in your Closing
                  Certificate. To have us resend your Closing Certificate,
                  please email us at{" "}
                  <a
                    href="mailto:closingcertificates@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={`Send an email to closingcertificates@loanpal.com in a new tab`}
                    style={{ color: "#4915f8", textDecoration: "underline" }}
                  >
                    closingcertificates@loanpal.com
                  </a>
                  . Or, you can review all your loan details on your Portal.
                </p>

                {/*    */}

                <h2>HOW DO I MAKE A PAYMENT ON MY LOAN?</h2>
                <p>
                  If you are paying by check, please include your Loan Agreement
                  Number on your check and mail it to: Loanpal, PO BOX 4387,
                  Portland, OR 97208.
                </p>
                <p>
                  If you have set up your payments to be made by Autopay, your
                  account will be withdrawn monthly for the exact amount of your
                  solar loan payment. To view your current Autopay settings,
                  please visit the{" "}
                  <a
                    href="https://www.billerpayments.com/app/cust/login.do?bsn=loanpalmain"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    online Portal
                  </a>{" "}
                  from the loanpal.com homepage.
                </p>

                {/*    */}

                <h2>HOW DO I ENROLL IN AUTOPAY?</h2>
                <p>
                  To enroll in ACH, please enter your Portal and follow the
                  instructions on the ACH tab.
                </p>

                {/*    */}

                <h2>
                  WHEN IS MY TARGET BALANCE DATE AND WHAT IS MY TARGET BALANCE?
                </h2>
                <p>
                  To view your Target Balance Date and Target Balance, please
                  log in to your Portal from the loanpal.com homepage.
                </p>

                {/*    */}

                <h2>
                  HOW DO I PAY BACK THE FEDERAL INVESTMENT TAX CREDIT INTO MY
                  LOAN?
                </h2>
                <p>
                  You may make any prepayment, including your residential tax
                  credit prepayment, by either sending a check or logging into
                  your personal payment portal and making a one-time payment via
                  ACH.
                </p>
                <p>
                  If you are paying by check, please include your Loan Agreement
                  Number on your check and mail it to Loanpal, PO BOX 4387,
                  Portland, OR 97208.
                </p>

                {/*    */}

                <h2>WILL I BE CHARGED ANY PREPAYMENT PENALTY?</h2>
                <p>
                  No, Loanpal does not assess any fee or penalty for
                  prepayments.
                </p>

                {/*    */}

                <h2>WHEN DOES INTEREST BEGIN TO ACCRUE ON MY LOAN?</h2>

                <p>
                  For most of our loans, interest begins accruing from the date
                  that Loanpal funds your loan. Please reference your loan
                  documents for specific dates.
                </p>

                {/*    */}

                <h2>ONCE APPROVED FOR CREDIT, HOW LONG IS IT VALID?</h2>

                <p>
                  Our credit approval is valid for 180 days from when we
                  initially run your credit. Your loan must fund within 180 days
                  of running your credit, or we will simply need to revalidate
                  your credit.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>

          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h1--regular" id={points[2]}>
              Home Sale/Refinance
            </Heading>

            <RawHTMLStyled>
              <>
                {/*    */}

                <h2>
                  WHAT IS A UCC-1 FINANCING STATEMENT AND IS IT A LIEN ON MY
                  HOME?
                </h2>
                <p>
                  We secure your Solar Loan through a lien on the solar
                  equipment itself by filing a UCC-1, or Uniform Commercial Code
                  Financing Statement, and county fixture filing. The filings
                  are not a lien against your home, so we do not hold any formal
                  position in your home (first, second, or otherwise). You will,
                  however, find a UCC-1 fixture filing on the title of your
                  property. We file UCC-1 and county fixture filings to protect
                  our rights as the financier of the system. If for whatever
                  reason your mortgage on the real property forecloses on your
                  home, the UCC-1 filing protects our security interest in the
                  system, while preventing your mortgage lender from taking
                  ownership of it.
                </p>

                <p>
                  If you have any questions about the UCC-1 or county fixture
                  filings, please contact us at{" "}
                  <a
                    href="mailto:solarinfo@loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={`Send an email to solarinfo@loanpal.com in a new tab`}
                  >
                    solarinfo@loanpal.com
                  </a>
                </p>

                {/*    */}

                <h2>
                  I WANT TO SELL MY HOME. WHAT DO I DO ABOUT MY LOANPAL LOAN?
                </h2>

                <p>You have two options:</p>
                <p>
                  In the vast majority of cases, homeowners choose to pay off
                  the remaining balance of the Loanpal loan through the sale of
                  their home.
                </p>
                <p>
                  However, if the new homeowner wishes to assume the loan,
                  he/she must apply to qualify for a Loanpal loan. If the new
                  homeowner is approved, then he/she assumes full responsibility
                  of the loan. If he/she is not approved, then you must pay off
                  the remaining balance of the Loanpal loan.
                </p>

                {/*    */}

                <h2>
                  WHAT ARE THE BENEFITS TO ROLLING MY SOLAR LOAN INTO MY
                  MORTGAGE?
                </h2>

                <p>
                  Through our Mortgage Division, we can offer you very
                  competitive interest rates on your mortgage. Because mortgage
                  interest is tax deductible, most of our customers can see
                  significant savings by refinancing and paying off their solar
                  loans with their mortgage. To understand the benefits of
                  rolling your solar loan into your mortgage, please call one of
                  our specialists at:{" "}
                  <a
                    href="tel:1-844-562-6725"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4915f8", textDecoration: "underline" }}
                  >
                    1-844-562-6725
                  </a>{" "}
                  or visit us at{" "}
                  <a
                    href="https://www.loanpal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={`Open this site homepage in a new tab`}
                  >
                    www.loanpal.com
                  </a>
                </p>

                {/*    */}

                <h2>WHAT HAPPENS TO THE UCC-1 DURING A REFINANCE?</h2>

                <p>
                  We may agree to lift our UCC-1 and county fixture filing on
                  the Solar Equipment for a limited period provided we will be
                  able to refile upon closing of the mortgage refinancing.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
        </Block>
        <ScrollToTop modifier={["skewed"]} guides={1} />
      </PointerParent>
    </Main>
  );
}
